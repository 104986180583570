// Timezones
export const TIMEZONES = [
  {
    offset: '-11:00',
    label: '(GMT-11:00) Pago Pago',
    tzCode: 'Pacific/Pago_Pago',
  },
  {
    offset: '-10:00',
    label: '(GMT-10:00) Hawaii Time',
    tzCode: 'Pacific/Honolulu',
  },
  { offset: '-10:00', label: '(GMT-10:00) Tahiti', tzCode: 'Pacific/Tahiti' },
  {
    offset: '-09:00',
    label: '(GMT-09:00) Alaska Time',
    tzCode: 'America/Anchorage',
  },
  {
    offset: '-08:00',
    label: '(GMT-08:00) Pacific Time',
    tzCode: 'America/Los_Angeles',
  },
  {
    offset: '-07:00',
    label: '(GMT-07:00) Mountain Time',
    tzCode: 'America/Denver',
  },
  {
    offset: '-06:00',
    label: '(GMT-06:00) Central Time',
    tzCode: 'America/Chicago',
  },
  {
    offset: '-05:00',
    label: '(GMT-05:00) Eastern Time',
    tzCode: 'America/New_York',
  },
  {
    offset: '-04:00',
    label: '(GMT-04:00) Atlantic Time - Halifax',
    tzCode: 'America/Halifax',
  },
  {
    offset: '-03:00',
    label: '(GMT-03:00) Buenos Aires',
    tzCode: 'America/Argentina/Buenos_Aires',
  },
  {
    offset: '-02:00',
    label: '(GMT-02:00) Sao Paulo',
    tzCode: 'America/Sao_Paulo',
  },
  { offset: '-01:00', label: '(GMT-01:00) Azores', tzCode: 'Atlantic/Azores' },
  { offset: '+00:00', label: '(GMT+00:00) London', tzCode: 'Europe/London' },
  { offset: '+01:00', label: '(GMT+01:00) Berlin', tzCode: 'Europe/Berlin' },
  {
    offset: '+02:00',
    label: '(GMT+02:00) Helsinki',
    tzCode: 'Europe/Helsinki',
  },
  {
    offset: '+03:00',
    label: '(GMT+03:00) Istanbul',
    tzCode: 'Europe/Istanbul',
  },
  { offset: '+04:00', label: '(GMT+04:00) Dubai', tzCode: 'Asia/Dubai' },
  { offset: '+04:30', label: '(GMT+04:30) Kabul', tzCode: 'Asia/Kabul' },
  {
    offset: '+05:00',
    label: '(GMT+05:00) Maldives',
    tzCode: 'Indian/Maldives',
  },
  {
    offset: '+05:30',
    label: '(GMT+05:30) India Standard Time',
    tzCode: 'Asia/Calcutta',
  },
  {
    offset: '+05:45',
    label: '(GMT+05:45) Kathmandu',
    tzCode: 'Asia/Kathmandu',
  },
  { offset: '+06:00', label: '(GMT+06:00) Dhaka', tzCode: 'Asia/Dhaka' },
  { offset: '+06:30', label: '(GMT+06:30) Cocos', tzCode: 'Indian/Cocos' },
  { offset: '+07:00', label: '(GMT+07:00) Bangkok', tzCode: 'Asia/Bangkok' },
  {
    offset: '+08:00',
    label: '(GMT+08:00) Hong Kong',
    tzCode: 'Asia/Hong_Kong',
  },
  {
    offset: '+08:30',
    label: '(GMT+08:30) Pyongyang',
    tzCode: 'Asia/Pyongyang',
  },
  { offset: '+09:00', label: '(GMT+09:00) Tokyo', tzCode: 'Asia/Tokyo' },
  {
    offset: '+09:30',
    label: '(GMT+09:30) Central Time - Darwin',
    tzCode: 'Australia/Darwin',
  },
  {
    offset: '+10:00',
    label: '(GMT+10:00) Eastern Time - Brisbane',
    tzCode: 'Australia/Brisbane',
  },
  {
    offset: '+10:30',
    label: '(GMT+10:30) Central Time - Adelaide',
    tzCode: 'Australia/Adelaide',
  },
  {
    offset: '+11:00',
    label: '(GMT+11:00) Eastern Time - Melbourne, Sydney',
    tzCode: 'Australia/Sydney',
  },
  { offset: '+12:00', label: '(GMT+12:00) Nauru', tzCode: 'Pacific/Nauru' },
  {
    offset: '+13:00',
    label: '(GMT+13:00) Auckland',
    tzCode: 'Pacific/Auckland',
  },
  {
    offset: '+14:00',
    label: '(GMT+14:00) Kiritimati',
    tzCode: 'Pacific/Kiritimati',
  },
];

// User Roles
export const USER_ROLES = ['Admin', 'Custom'];

// Survey Form
export const FORM_COMPONENT_TYPES = [
  'Radio',
  'Checkbox',
  'Text',
  'Email',
  'TextArea',
  'Dropdown',
  'HTML',
  'Canvas',
  'Slider',
  'NPS',
  'Scale',
  'Date',
  'Ranking',
  // 'Grid',
  // 'Compare',
  'Star Rating',
  'Age',
  'ImageVideo',
];

export const FORM_COMPONENT_TYPES_SIDE = [
  'Radio',
  'Checkbox',
  'Text',
  // 'Dropdown',
  // 'TextArea',
  // 'HTML',
];

export const FORM_HELPER_TEXT = {
  Radio: 'Select one of the following options',
  Checkbox: 'Select all that apply',
  Text: 'Please write your answer below',
  Email: 'Enter your email address',
  Phone: 'Enter your phone number',
  TextArea: 'Type in the text box below',
  Dropdown: 'Select one of the following options',
  Slider: 'Move the slider to make your selection',
  Ranking: 'Drag and drop the below options to change the order.',
  HTML: null,
  Canvas: null,
  ImageVideo: 'Upload an asset',
  Grid: 'Please select based on the scale',
  NPS: 'Select one of the following options',
  Scale: 'Select one of the following options',
  Date: 'Date Picker',
  'Star Rating': 'Click to give your rating.',
  Age: 'Move the slider to make your selection',
  Compare: 'Select one of the following groups',
};

export const FORM_COMPONENT_ICONS = {
  Radio: 'mdi-radiobox-marked',
  Checkbox: 'mdi-checkbox-multiple-marked-outline',
  Text: 'mdi-cursor-text',
  Email: 'mdi-at',
  Phone: 'mdi-phone',
  TextArea: 'mdi-card-text-outline',
  Dropdown: 'mdi-arrow-down-drop-circle-outline',
  Slider: 'mdi-dots-horizontal',
  Ranking: 'mdi-format-list-numbered',
  HTML: 'mdi-code-tags',
  Canvas: 'mdi-code-tags',
  Grid: 'mdi-grid',
  NPS: 'mdi-crosshairs',
  Scale: 'mdi-scale',
  Date: 'mdi-calendar',
  'Star Rating': 'mdi-star',
  DynamicEmbed: 'fas fa-arrows-alt',
  PromoAction: 'fas fa-badge-percent',
  SocialAction: 'fas fa-share-alt',
  Age: 'mdi-account',
  Compare: 'mdi-file-table-box-outline',
  ImageVideo: 'mdi-video-image',
};

export const FORM_COMPONENT_COLORS = {
  Radio: '#702EB4',
  Checkbox: '#DD00FF',
  Text: '#6C3BE8',
  Email: '#3B85E8',
  TextArea: '#46EE90',
  Dropdown: '#76EE46',
  Slider: '#C5EE46',
  Ranking: '#E0EE46',
  HTML: '#F285AD',
  Canvas: '#F285AD',
  Grid: '#702EB4',
  NPS: '#3B85E8',
  Scale: '#3B85E8',
  Date: '#3B85E8',
  'Star Rating': '#76EE46',
  Age: '#702EB4',
  Compare: '#C5EE46',
};

export const QUESTION_TEMPLATES = [
  {
    id: '0',
    label: 'Scale 1 - 2',
    title: 'On A Scale of 1 Through 10 What did you think?',
    values: [{ label: '1' }, { label: '2' }],
  },
  {
    id: '1',
    label: 'Agree / Disagree',
    title: 'Do you agree?',
    values: [
      { label: 'Strongly Disagree' },
      { label: 'Disagree' },
      { label: 'Not Sure' },
      { label: 'Agree' },
      { label: 'Strongly Agree' },
    ],
  },
];


// Survey Settings Embed Theme
export const SURVEY_EMBED_THEME = {
  PRIMARY: '#1976D2',
  ACCENT: '#82B1FF',
};

export const DISCOUNT_UNITS = [
  {
    label: '%',
    value: '%',
  },
  {
    label: '$',
    value: '$',
  },
];

export const STRIPE = {
  status: {
    subscription: {
      TRIALING: 'trialing',
      ACTIVE: 'active',
      CANCELED: 'canceled',
      INACTIVE: 'inactive',
    },
  },
};

export const IMPORT_COLUMNS = {
  RESPONSE: [
    'Survey Title',
    'Coupon Code',
    'Started At',
    'Submitted At',
    'Order ID',
    'Order Number',
  ],
  CUSTOMER: [
    'Customer ID',
    'Email',
    'Phone',
    'Full Name',
    'First Name',
    'Last Name',
    'Address1',
    'City',
    'Zip',
    'Province',
    'Country',
    'Company',
    'Accepts Marketing',
    'Financial Status',
    'Total Spent',
    'Orders Count',
    'Line Items',
  ],
};

export const EXPORT_COLUMNS = {
  CUSTOMER_JOURNEY: [
    'First Visit Source',
    'First Visit UTM Source',
    'First Visit UTM Details',
    'Last Visit Source',
    'Last Visit UTM Source',
    'Last Visit UTM Details',
    'First visit to purchase time',
    'Customer purchase order',
  ],
};

// TODO: constants for all question type strings?
export const ACTION_TYPES = new Set([
  'HTML',
  'SocialAction',
  'PromoAction',
  'DynamicEmbed',
  'Canvas',
]);

export const SOCIAL_CHANNEL_OPTIONS = [
  {
    label: '',
    link: '',
    on: true,
    title: '',
    type: 'facebook',
  },
  {
    label: '',
    link: '',
    on: true,
    title: '',
    type: 'snapchat',
  },
  {
    label: '',
    link: '',
    on: true,
    title: '',
    type: 'tiktok',
  },
  {
    label: '',
    link: '',
    on: false,
    title: '',
    type: 'x-twitter',
  },
  {
    label: '',
    link: '',
    on: false,
    title: '',
    type: 'threads',
  },
  {
    label: '',
    link: '',
    on: false,
    title: '',
    type: 'discord',
  },
  {
    label: '',
    link: '',
    on: false,
    title: '',
    type: 'reddit',
  },
  {
    label: '',
    link: '',
    on: false,
    title: '',
    type: 'linkedin',
  },
  {
    label: '',
    link: '',
    on: false,
    title: '',
    type: 'instagram',
  },
  {
    label: '',
    link: '',
    on: false,
    title: '',
    type: 'youtube',
  }
];

export const SOCIAL_COLORS = {
  'x-twitter': '#000000',
  twitter: '#1da1f2',
  facebook: '#1877f2',
  instagram: '#C14A88',
  linkedin: '#0077b5',
  threads: '#000000',
  reddit: '#ff4500',
  discord: '#7289da',
  youtube: '#ff0000',
  tiktok: '#69c9d0',
  snapchat: '#000000',
};

// TODO: better name for this
export const STANDARD_TYPES = new Set([
  'Radio',
  'Checkbox',
  'Ranking',
  'Dropdown',
  'Compare',
]);

export const INPUT_TYPES_TITLES = {
  RADIO: 'Radio',
  CHECKBOX: 'Checkbox',
  TEXT: 'Text',
  TEXTAREA: 'TextArea',
  EMAIL: 'Email',
  PHONE: 'Phone',
  DROPDOWN: 'Dropdown',
  SLIDER: 'Slider',
  NPS: 'NPS',
  RANKING: 'Ranking',
  SCALE: 'Scale',
  DATE: 'Date',
  HTML: 'HTML',
  CANVAS: 'Canvas',
  DYNAMIC_EMBED: 'Dynamic Embed',
  PROMO_CODE_REDEMPTION: 'Promo Code Redemption',
  IMAGE_VIDEO: 'Image or Video Upload',
  SOCIAL_CHANNEL_LINKS: 'Social Channel Links',
};

export const QUESTION_INPUT_TYPES = [
  {
    title: INPUT_TYPES_TITLES.RADIO,
    medium: 'Question',
    inputType: 'Radio',
    description: 'A base radio input (single-select) ',
    source: 'Start',
    editable: true,
  },
  {
    title: INPUT_TYPES_TITLES.CHECKBOX,
    medium: 'Question',
    inputType: 'Checkbox',
    description: 'A base checkbox input (multi-select)',
    source: 'Start',
    editable: true,
  },
  {
    title: INPUT_TYPES_TITLES.TEXT,
    medium: 'Question',
    inputType: 'Text',
    description: 'A base text input',
    source: 'Start',
    editable: true,
  },
  {
    title: INPUT_TYPES_TITLES.TEXTAREA,
    medium: 'Question',
    inputType: 'TextArea',
    description: 'A Base TextArea Left Blank',
    source: 'Start',
    editable: true,
  },
  {
    title: INPUT_TYPES_TITLES.EMAIL,
    medium: 'Question',
    inputType: 'Email',
    description: 'A text input with email validation',
    source: 'Start',
    editable: true,
  },
  {
    title: INPUT_TYPES_TITLES.PHONE,
    medium: 'Question',
    inputType: 'Phone',
    description: 'A base phone input',
    source: 'Start',
    editable: true,
  },
  {
    title: INPUT_TYPES_TITLES.DROPDOWN,
    medium: 'Question',
    inputType: 'Dropdown',
    description: 'A Base Dropdown Left Blank',
    source: 'Start',
    editable: true,
  },
  {
    title: INPUT_TYPES_TITLES.SLIDER,
    medium: 'Question',
    inputType: 'Slider',
    description: 'Number slider',
    source: 'Start',
    editable: true,
  },
  {
    title: INPUT_TYPES_TITLES.NPS,
    medium: 'Question',
    inputType: 'NPS',
    description: 'Start tracking your NPS score',
    source: 'Start',
    editable: true,
  },
  {
    title: INPUT_TYPES_TITLES.RANKING,
    medium: 'Question',
    inputType: 'Ranking',
    description: 'A sortable list for ranking items',
    source: 'Start',
    editable: true,
  },
  {
    title: INPUT_TYPES_TITLES.SCALE,
    medium: 'Question',
    inputType: 'Scale',
    description: 'A base scale input',
    source: 'Start',
    editable: true,
  },
  {
    title: INPUT_TYPES_TITLES.IMAGE_VIDEO,
    medium: 'Question',
    inputType: 'ImageVideo',
    description: 'A Image or Video upload',
    source: 'Start',
    editable: true,
    settings: {},
  },
  {
    title: INPUT_TYPES_TITLES.DATE,
    medium: 'Question',
    inputType: 'Date',
    description: 'A base Date Picker',
    source: 'Start',
    editable: true,
  },
  {
    title: INPUT_TYPES_TITLES.HTML,
    medium: 'Action',
    inputType: 'HTML',
    description: 'A blank HTML editor to create custom actions.',
    source: 'Start',
    editable: true,
  },
  {
    title: INPUT_TYPES_TITLES.CANVAS,
    medium: 'Action',
    inputType: 'Canvas',
    description: 'A flexible editor to create custom survey actions.',
    source: 'Start',
    editable: true,
  },
  {
    title: INPUT_TYPES_TITLES.DYNAMIC_EMBED,
    medium: 'Action',
    inputType: 'DynamicEmbed',
    description:
      'Dynamically embed other widgets from your confirmation screen for targeted display.',
    source: 'Start',
    editable: true,
  },
  {
    title: INPUT_TYPES_TITLES.PROMO_CODE_REDEMPTION,
    medium: 'Action',
    inputType: 'PromoAction',
    description: 'Generate a one-time use promo code.',
    shopify: true,
    source: 'Start',
    editable: true,
  },
  {
    title: INPUT_TYPES_TITLES.SOCIAL_CHANNEL_LINKS,
    medium: 'Action',
    inputType: 'SocialAction',
    description: 'Invite customers to follow you.',
    source: 'Start',
    editable: true,
  },
  // {
  //   title: 'Add to Email List',
  //   medium: 'Action',
  //   inputType: 'EmailAction',
  //   description: 'A sortable list for ranking items.',
  // },
  // {
  //   title: 'Add to SMS List',
  //   medium: 'Action',
  //   inputType: 'SMSAction',
  //   description: 'A sortable list for ranking items.',
  // },
  // {
  //   title: 'Grid Matrix',
  //   inputType: 'Grid',
  //   description: 'A matrix radio input',
  // },
  // {
  //   title: 'Star Rating',
  //   inputType: 'Star Rating',
  //   description: 'Rate using stars',
  // },
  // {
  //   title: 'Age',
  //   inputType: 'Age',
  //   description: 'Have customers tell you their age',
  // },
  // {
  //   title: 'Compare',
  //   inputType: 'Compare',
  //   description: 'A radio that compares two values',
  // },
];

export const REGISTER_QUESTIONS = [
  {
    title: 'Which best describes your Industry?',
    items: [
      'Art & Photography',
      'Clothing & Fashion',
      'Jewelry & Accessories',
      'Food & Beverage',
      'Electronics & Tech',
      'Home & Garden',
      'Furniture',
      'Digital products (ebooks, courses, etc.)',
      'Health & Beauty',
      'Sports & Recreation',
      'Toys & Games',
      'Other',
    ],
    value: '',
    otherValue: '',
    name: 'industry',
    required: true,
  },
  {
    title: 'Where is your company based?',
    name: 'country',
    items: [
      'Afghanistan',
      'Albania',
      'Algeria',
      'Andorra',
      'Angola',
      'Antigua & Deps',
      'Argentina',
      'Armenia',
      'Australia',
      'Austria',
      'Azerbaijan',
      'Bahamas',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bhutan',
      'Bolivia',
      'Bosnia Herzegovina',
      'Botswana',
      'Brazil',
      'Brunei',
      'Bulgaria',
      'Burkina',
      'Burundi',
      'Cambodia',
      'Cameroon',
      'Canada',
      'Cape Verde',
      'Central African Rep',
      'Chad',
      'Chile',
      'China',
      'Colombia',
      'Comoros',
      'Congo',
      'Congo {Democratic Rep}',
      'Costa Rica',
      'Croatia',
      'Cuba',
      'Cyprus',
      'Czech Republic',
      'Denmark',
      'Djibouti',
      'Dominica',
      'Dominican Republic',
      'East Timor',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Eritrea',
      'Estonia',
      'Ethiopia',
      'Fiji',
      'Finland',
      'France',
      'Gabon',
      'Gambia',
      'Georgia',
      'Germany',
      'Ghana',
      'Greece',
      'Grenada',
      'Guatemala',
      'Guinea',
      'Guinea-Bissau',
      'Guyana',
      'Haiti',
      'Honduras',
      'Hungary',
      'Iceland',
      'India',
      'Indonesia',
      'Iran',
      'Iraq',
      'Ireland {Republic}',
      'Israel',
      'Italy',
      'Ivory Coast',
      'Jamaica',
      'Japan',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kiribati',
      'Korea North',
      'Korea South',
      'Kosovo',
      'Kuwait',
      'Kyrgyzstan',
      'Laos',
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Macedonia',
      'Madagascar',
      'Malawi',
      'Malaysia',
      'Maldives',
      'Mali',
      'Malta',
      'Marshall Islands',
      'Mauritania',
      'Mauritius',
      'Mexico',
      'Micronesia',
      'Moldova',
      'Monaco',
      'Mongolia',
      'Montenegro',
      'Morocco',
      'Mozambique',
      'Myanmar, {Burma}',
      'Namibia',
      'Nauru',
      'Nepal',
      'Netherlands',
      'New Zealand',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'Norway',
      'Oman',
      'Pakistan',
      'Palau',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Philippines',
      'Poland',
      'Portugal',
      'Qatar',
      'Romania',
      'Russian Federation',
      'Rwanda',
      'St Kitts & Nevis',
      'St Lucia',
      'Saint Vincent & the Grenadines',
      'Samoa',
      'San Marino',
      'Sao Tome & Principe',
      'Saudi Arabia',
      'Senegal',
      'Serbia',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Slovakia',
      'Slovenia',
      'Solomon Islands',
      'Somalia',
      'South Africa',
      'South Sudan',
      'Spain',
      'Sri Lanka',
      'Sudan',
      'Suriname',
      'Swaziland',
      'Sweden',
      'Switzerland',
      'Syria',
      'Taiwan',
      'Tajikistan',
      'Tanzania',
      'Thailand',
      'Togo',
      'Tonga',
      'Trinidad & Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Tuvalu',
      'Uganda',
      'Ukraine',
      'United Arab Emirates',
      'United Kingdom',
      'United States',
      'Uruguay',
      'Uzbekistan',
      'Vanuatu',
      'Vatican City',
      'Venezuela',
      'Vietnam',
      'Yemen',
      'Zambia',
      'Zimbabwe',
    ],
    value: '',
    required: true,
  },
  {
    title: 'State/Province',
    name: 'state',
    items: {
      'United States': [
        'Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming',
      ],
      Canada: [
        'Alberta',
        'British Columbia',
        'Manitoba',
        'New Brunswick',
        'Newfoundland and Labrador',
        'Nova Scotia',
        'Ontario',
        'Prince Edward Island',
        'Quebec',
        'Saskatchewan',
        'Northwest Territories',
        'Nunavut',
        'Yukon',
      ],
    },
    value: '',
    required: true,
    parent: 'country',
  },
  {
    title: 'How did you first discover KnoCommerce?',
    items: [
      'Shopify app store search',
      'Google search',
      'Someone I follow',
      'A paid advertisement',
      'Agency or Tech partner',
      'Saw KnoCommerce on another site',
      'Podcast or Webinar',
      'Twitter or LinkedIn',
      'From a friend, coworker, etc.',
      'Other',
    ],
    value: '',
    name: 'source',
    otherValue: '',
    required: true,
  },
  {
    title: {
      'Shopify app store search': 'What did you search for?',
      'Google search': 'What did you search for?',
      'Someone I follow': 'Who?',
      'A paid advertisement': 'Where did you see the ad?',
      'Agency or Tech partner': 'Which one?',
      'Saw KnoCommerce on another site': 'Which one?',
      'Podcast or Webinar': 'Which one?',
      'Twitter or LinkedIn': 'Whose account?',
      'From a friend, coworker, etc.': 'Tell us who?',
      Other: 'Please specify',
    },
    name: 'source-detail',
    value: '',
    hideFor: [],
    required: false,
    parent: 'source',
  },
  {
    title: 'How many online orders do you average per month?',
    items: [
      '< 200',
      '200 - 1,000',
      '1,001 - 2,000',
      '2,001 - 5,000',
      '5,001 - 10,000',
      '10,000+',
    ],
    value: '',
    name: 'orders',
    required: true,
  },
  {
    title: 'About how much do you spend on advertising every month?',
    items: [
      '< $10,000',
      '$10,001 - $50,000',
      '$50,001 - $100,000',
      '$100,001 - $1,000,000',
      '$1,000,001 - $10,000,000',
      '$10,000,000+',
    ],
    value: '',
    name: 'advertising-spend',
    required: true,
  },
  {
    title: 'Which problem(s) are you hoping to address?',
    items: [
      'Marketing attribution',
      'Customer insights',
      'Audience building',
      'Customer satisfaction',
    ],
    multiple: true,
    value: '',
    name: 'solution',
    required: true,
  },
  {
    title: 'Are you switching from another survey tool?',
    items: ['Yes', 'No'],
    value: '',
    name: 'survey',
    required: false,
  },
  {
    title: {
      Yes: 'Which survey tool?',
      No: '',
    },
    name: 'survey-detail',
    value: '',
    hideFor: ['No'],
    required: false,
    parent: 'survey',
  },
];

export const PLAN_FEATURES = [
  'Active Surveys',
  'Audiences',
  'Questions/Actions per survey',
  'Actions Enabled',
  'Klaviyo Integration',
  'Shopify Order Tagging',
  'Send surveys with a link',
  'Rest API Access',
  'Dedicated Slack Channel',
  'Fully managed onboarding',
  // 'Remove Branding',
];

export const UPGRADE_BLURB = {
  ALLOW_API_CLIENTS:
    'API access isn’t available on your current plan. Upgrade to unlock it.',
  ACTIONS:
    'Actions are a great way to push your customers to do something like refer a friend, follow you on social, download your app, etc. Upgrade to unlock actions.',
  ALLOWED_QUESTIONS:
    'This question/action isn’t available on your current plan. Upgrade to unlock it.',
  TEMPLATE_ALLOWED:
    'This template isn’t available on your current plan. Upgrade to unlock it.',
  AUDIENCES:
    'Your current plan doesn’t allow for audiences, or you’ve already reached the limit for audiences. Upgrade to unlock better insight with audiences!',
  GENERIC:
    'Upgrade your plan to unlock more options and features. Upgrade now to access all of our platform’s capabilities and get the most out of your experience.',
  KNO_LINK:
    'Looking to send your customers a survey with a link? Upgrade to send surveys anywhere you can deliver a link (email, sms social post, etc.).',
  NOT_ALLOWED_INTEGRATION:
    'This integration isn’t available on your current plan. Upgrade to unlock it.',
  NOT_ALLOWED_INTEGRATION_PREMIUM:
    'This is a premium integration and isn’t available on your current plan. Upgrade to unlock it.',
  NOT_ALLOWED_INTEGRATION_MAX:
    `You've reached your maximum number of integrations Upgrade to unlock more.`,
  PUBLISH_SURVEY_LIMIT:
    'You already have the maximum number of published surveys for your plan. Unpublish a survey, or upgrade to enable more surveys.',
  QUESTION_LIMIT:
    'You’ve added the maximum number of questions for your plan. Upgrade to add additional questions to this survey.',
  SEGMENT_LIMIT:
    'You’ve added the maximum number of audiences for your plan. Upgrade to enable more audiences and get more insight!',
  SURVEY_LIMIT:
    'You’ve enabled the maximum number of surveys for your plan. Upgrade to enable more surveys.',
  SURVEY_TEMPLATES:
    'This template has too many questions or actions for your current plan. Upgrade to the next plan to use this survey template.',
  QUESTION_TYPE_POST_PURCHASE:
    'This Question Type isn’t available for Kno Links',
  BETA_NOT_ALLOWED:
    'Upgrade to the next plan to have access to the beta enviroment.',
  IMPORTS:
    'Your current plan doesn’t allow imports. Upgrade your plan to unlock along with other features.',
};

export const IO_STATUS = {
  COMPLETE: 'complete',
  ERROR: 'error',
  QUEUED: 'queued',
  UPLOADED: 'uploaded',
};

export const IO_TYPES = {
  IMPORT_RESPONSES: 'import.responses',
  EXPORT_RESPONSES: 'export.responses',
};

export const SERVICES = {
  CUSTOM: 'CUSTOM',
  KLAVIYO: 'KLAVIYO',
  PEEL: 'PEEL',
  SHOPIFY: 'SHOPIFY',
  SHOPIFY_TAGGING: 'SHOPIFY_TAGGING',
  SOURCE_MEDIUM: 'SOURCE_MEDIUM',
  TIKTOK: 'TIKTOK',
  TRIPLE_WHALE: 'TRIPLE_WHALE',
  KLAR: 'KLAR',
  YOTPO: 'YOTPO',
  ROCKERBOX: 'ROCKERBOX',
  DAASITY: 'DAASITY',
};

export const CURRENCIES = [
  'COP',
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BTC',
  'BTN',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLF',
  'CLP',
  'CNH',
  'CNY',
  'CRC',
  'CUC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ERN',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GGP',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'IMP',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JEP',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KPW',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRU',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLL',
  'SOS',
  'SRD',
  'SSP',
  'STD',
  'STN',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'UYU',
  'UZS',
  'VES',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XAG',
  'XAU',
  'XCD',
  'XDR',
  'XOF',
  'XPD',
  'XPF',
  'XPT',
  'YER',
  'ZAR',
  'ZMW',
  'ZWL',
];

export const STANDARD_TEMPLATE_ID_TIKTOK = 'a83b13d5-026a-48ba-ab2c-6a071f1c6b5c';


export const STANDARD_SURVEY_TIKTOK = {
  title: 'TikTok Survey',
  questions: [],
  enabled: true,
  settings: {
    surveyType: 'post_purchase',
    customText: {
      prevLabel: '',
      nextLabel: '',
    },
    embed: {
      on: true,
      custom: false,
      segment: null,
      position: 'additional_scripts',
      theme: {
        darkMode: false,
        pallette: {
          backgroundColor: '#ffffff',
          primary: '#474747',
          emailSubmissionButton: '#474747',
          emailSubmissionButtonText: '#ffffff',
          button: '#474747',
          buttonText: '#ffffff',
        },
        styleString: null,
      },
    },
    logo: null,
    disabled: {
      time: null,
    },
    confirmation: {
      html: '<p>Thank you for taking this survey!</p>',
    },
    header: {
      html: '',
    },
    footer: {
      html: '',
    },
    discount: {
      custom: {
        isEnabled: false,
      },
      prefix: '',
      entitledProductIds: '',
    },
    surveyOptions: {
      customerSurveyOnly: true,
      emailCapture: false,
      email: false,
      phone: false,
    },
  },
  tiktokWhiteLabel: true,
};

export const TIKTOK_EVENTS = {
  AUTHORIZATION_COMPLETED: 'AUTHORIZATION_COMPLETED',
  SHOP_INTEGRATION_COMPLETED: 'SHOP_INTEGRATION_COMPLETED',
  SURVEY_LIVE: 'SURVEY_LIVE',
  SURVEY_STOP: 'SURVEY_STOP',
  FIRST_SURVEY_RESPONSE_SUBMITTED: 'FIRST_SURVEY_RESPONSE_SUBMITTED',
};

export const TIKTOK_COLORS = {
  TEAL: '#25F4EE', // TODO: cruft?
  GREEN: '#08807C',
  RED: '#FE2C55',
}
