import dateFormat from '@/helpers/dateFormat';
import _ from 'lodash';
import moment from 'moment-timezone';
import toastr from 'toastr';
import { mapState } from 'vuex';
import ProfileService from '@/services/ProfileService';
import { IntegrationService } from '@/services/IntegrationService';

const colorList = [
  'red',
  'indigo',
  'purple',
  'blue',
  'green',
  'orange',
  'pink',
];

export default {
  computed: {
    ...mapState('appStore', {
      appState: (state) => state,
    }),
    ...mapState('authStore', {
      accountData: (state) => state.accountData,
      userData: (state) => state.userData,
      tiktokConnectState: (state) => state.tiktokConnectState,
    }),
    localTimezone() {
      return moment.tz.guess();
    },
    accountTimezone() {
      return _.get(this.accountData, 'settings.timezone')
        ? _.get(this.accountData, 'settings.timezone')
        : moment.tz.guess();
    },
    legacyUnlimited() {
      return this.accountData.tier && this.accountData.tier.legacy;
    },
    exceedsSurveyLimit() {
      if (this.isSuper) return false;
      const { plan } = this.appState;
      if (this.legacyUnlimited || plan.maxActiveExperiences === null) {
        return false;
      }
      const { tier } = this.accountData;
      return tier.activeExperiences >= plan.maxActiveExperiences;
    },
    accountName() {
      return this.isSuper ? 'Super' : this.accountData.name;
    },
    accountPermissions() {
      return this.accountData.permissions;
    },
    accountSubscription() {
      return this.accountData.subscription;
    },
    userNameAbbr() {
      if (!this.userData) return '';
      const { name } = this.userData;
      return name[0].toUpperCase();
    },
    userRole() {
      return this.userData.role;
    },
    isSuper() {
      return this.userData.isSuperAdmin;
    },
    isAdmin() {
      return this.userRole === 'Admin';
    },
    isUser() {
      return this.userRole === 'Custom';
    },
    isTikTokUser() {
      return this.accountData?.plan?.lookupKey === 'tiktok-free';
    },
    useTiktokStyles() {
      return this.isTikTokUser || !!this.tiktokConnectState.plan;
    },
  },
  methods: {
    getValidators(fieldLabel, rules) {
      return rules.map((rule) => {

        if (rule === 'required') {
          return (v) => ((Array.isArray(v) && v.length <= 0) || !v) ? `${fieldLabel} is required` : true;
        }

        if (rule === 'number') {
          return (v) => /^\d+$/.test(v) ? `${fieldLabel} is required` : true;
        }

        // TODO: extend this validation to include more valid emails
        if (rule === 'email') {
          return (v) =>
            /* eslint-disable-next-line no-useless-escape */
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
              v?.trim(),
            ) || `${fieldLabel} must be valid`;
        }

        if (rule === 'phone') {
          return (v) =>
            /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g.test(
              v,
            ) || `${fieldLabel} must be valid`;
        }

        if (rule === 'url') {
          return (v) =>
            /^([a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.){1,2}[a-zA-Z]{2,}$/.test(
              v,
            ) || `${fieldLabel} must be valid`;
        }

        if (rule === 'urlv2') {
          return (v) =>
          /* eslint-disable-next-line no-useless-escape */
          /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/.test(v) || `${fieldLabel} must be valid`;
        }

        if (rule === 'shopify-subdomain') {
          return (v) =>
          /* eslint-disable-next-line no-useless-escape */
          /^[a-zA-Z0-9-]+$/.test(v) || `${fieldLabel} must be valid`;
        }

        const sections = rule.split(':');
        const [mainRule] = sections;

        if (mainRule === 'max-len') {
          const maxLength = parseInt(sections[1], 10);
          return (v) =>
            (!!v && v.length <= maxLength) ||
            `${fieldLabel} must be less than ${maxLength} ${
              typeof v === 'string' ? 'characters' : 'items'
            }`;
        }

        if (mainRule === 'min-len') {
          const minLength = parseInt(sections[1], 10);
          return (v) =>
            (!!v && v.length >= minLength) ||
            `${fieldLabel} must be more than ${minLength} ${
              typeof v === 'string' ? 'characters' : 'items'
            }`;
        }

        if (mainRule === 'max-val') {
          const maxValue = parseFloat(sections[1]);
          return (v) =>
            (parseFloat(v) <= maxValue) ||
            `${fieldLabel} must be smaller than or equal to ${maxValue}`;
        }

        if (mainRule === 'min-val') {
          const minValue = parseFloat(sections[1]);
          return (v) =>
            (parseFloat(v) >= minValue) ||
            `${fieldLabel} must be greater than or equal to ${minValue}`;
        }

        if (mainRule === 'equal') {
          const otherFieldName = sections[1];
          const otherFieldLabel = sections[2];
          return (v) =>
            (!!v && v === _.get(this, otherFieldName)) ||
            `${fieldLabel} must be equal to ${otherFieldLabel}`;
        }

        if (mainRule === 'date') {
          const format = sections[1];
          const df = dateFormat.list[format];
          const helpFormat = () => {
            const now = new Date();
            const d = now.getDate();
            const m = dateFormat.MONTHS[now.getMonth() + 1];
            return format === 'MMMM DD'
              ? `${m[0].toUpperCase()}${m.slice(1)} ${d}`
              : format;
          };

          return (v) =>
            (!!v &&
              (format === 'MMMM DD'
                ? !!df &&
                  dateFormat.MONTHS.includes(v.toLowerCase().split(' ')[0])
                : !!df) &&
              df.regex.test(v)) ||
            `${fieldLabel} must use format ${helpFormat()}`;
        }

        return null;
      });
    },
    getColor(id) {
      const index = id
        .split('')
        .reduce((sum, value) => sum + value.charCodeAt(), 0);
      return colorList[index % colorList.length];
    },
    mergeHTML(html, customer) {
      if (!customer) return html;

      try {
        customer.metadata = customer?.metadata ?? {};

        const decoded = {};

        // DEBUG:
        Object.keys(customer.metadata).forEach((key) => {
          try {
            let prop = customer.metadata[key]?.replace(/\+/g, ' ');
            decoded[key] = decodeURIComponent(prop);
          } catch (err) {
            console.error(`key ${key} => ${err.message}`);
          }
        });

        const meta = {
          ...customer,
          ...decoded,
        };
        delete meta.metadata;

        const regExp = new RegExp(/{{(\w+)(\|*(.*?))}}/, 'g');
        const matched = html.match(regExp);

        const mergeFields = [];
        if (matched && matched.length) {
          matched.forEach((key) => {
            const k = key.slice(2, -2);
            if (meta[k]) {
              mergeFields.push(k);
            } else if (k.includes('|')) {
              // include fallback values
              mergeFields.push(k.split('|'));
            }
          });
        }

        if (meta && mergeFields.length) {
          mergeFields.forEach((field) => {
            if (meta[field]) {
              html = html.replaceAll(`{{${field}}}`, meta[field]);
            } else if (Array.isArray(field)) {
              html = html.replaceAll(`{{${field[0]}|${field[1]}}}`, field[1]);
            }
          });
        }
      } catch (err) {
        console.error(err);
      }

      return html;
    },
    decodeHtml(html) {
      const textElement = document.createElement('textarea');
      textElement.innerHTML = html;
      const decodedHTML = textElement.value;
      textElement.remove();
      return decodedHTML;
    },
    handleErrorResponse(err) {
      console.error(err);
      let message;
      if (err?.response?.data?.message) {
        message = err.response.data.message;
      } else if (err?.message) {
        message = err.message;
      } else if (err?.error) {
        message = err.error;
      } else {
        message = 'Oops! Somethings wrong';
      }
      (this.$toastr || toastr).error(message, 'Error!');
    },
    changeDateTimezone(date, to, format = 'YYYY-MM-DD hh:mm:ss A') {
      return moment.utc(date).tz(to).format(format);
    },
    exceedsSurveyQuestionLimit(numQuestions) {
      if (this.isSuper) return false;
      const { plan } = this.accountData;
      if (this.legacyUnlimited || plan.maxQuestionsPerExperience === null) {
        return false;
      }
      return numQuestions > plan.maxQuestionsPerExperience;
    },
    exceedsSegmentLimit(numActiveAudiences) {
      if (this.isSuper) return false;
      const { plan } = this.accountData;
      if (this.legacyUnlimited || plan.maxActiveAudiences === null) {
        return false;
      }
      return numActiveAudiences > plan.maxActiveAudiences;
    },
    formatDateRange(dateRange) {
      const start = dateRange.start ?
        moment.tz(dateRange.start, this.accountTimezone).startOf('day').toDate() : null;
      const end = dateRange.end ?
        moment.tz(dateRange.end, this.accountTimezone).endOf('day').toDate() : null;
      return { start, end };
    },
    hasPermission(permission) {
      if (permission === undefined) return true;

      /* NOTE: Account permissions do not make sense since we are removing plan permissions */
      // const accountPermissions = this.accountData.permissions || [];
      // const found = accountPermissions.find((ap) => ap.title === permission);

      if (this.isAdmin) return true;

      const permissions = this.userData.permissions || [];
      return permissions.some((p) => p === permission);
    },
    async fetchCurrentProfile() {
      try {
        const { data } = await ProfileService.methods.getProfile();
        await this.$store.dispatch('authStore/setAccountData', data);
        await this.$store.dispatch('authStore/setUserData', data.user);
        await this.$store.dispatch('appStore/setPlan', data.plan);
        return data;
      } catch (err) {
        // Clear localStorage
        await this.$store.dispatch('authStore/resetAuthState');
        await this.$store.dispatch('appStore/resetAppState');
        $helpers.logout();
        return null;
      }
    },
    async fetchIntegrations() {
      try {
        const { data } = await IntegrationService.methods.getIntegrations();
        await this.$store.dispatch('appStore/setIntegrations', data);

        const hasKlaviyo = !!data.find((integration) => integration.serviceType === 'KLAVIYO');
        const hasShopify = !!data.find((integration) => integration.serviceType === 'SHOPIFY');
        const hasTiktok = !!data.find((integration) => integration.serviceType === 'TIKTOK');
        const hasYotpo = !!data.find((integration) => integration.serviceType === 'YOTPO');
        const hasRockerbox = !!data.find((integration) => integration.serviceType === 'ROCKERBOX');
        this.$store.dispatch('authStore/setAccountData', { hasKlaviyo, hasShopify, hasTiktok, hasYotpo, hasRockerbox });

      } catch (err) {
        this.handleErrorResponse(err);
      }
    },
    formatBytes(bytes, decimals = 2) {
      // https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
      if (!+bytes) return '0 Bytes';

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    debounce(timer, delay, params, func) {
      if (timer) {
        clearTimeout(timer);
      }
      return setTimeout(() => func(params), delay);
    }
  },
};
